.Feedback-grid-container {
  font-family: ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, 新ゴR, メイリオ,
    Meiryo, Verdana, ＭＳ Ｐゴシック, sans-serif;
}

.Feedback-comment-input-style {
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 0px;
  font-weight: bold;
  height: 75px;
  width: 80vw;
}

.Feedback-text-label {
  font-style: normal;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: left;
  color: #000000;
  margin-top: 5px;
  margin-left: 10px;
}

.Feedback-header {
  font-family: ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, 新ゴR, メイリオ,
    Meiryo, Verdana, ＭＳ Ｐゴシック, sans-serif;
  left: 12.06%;
  right: 67.08%;
  top: 8.85%;
  bottom: 88.72%;
  font-style: normal;
  font-weight: 650;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.Feedback-card {
  background-color: #fff;
  margin-top: 23%;
  margin-bottom: 1%;
  height: 93%;
  border-radius: 10;
  box-shadow: none;
}

.Feedback-card-header {
  border-bottom: 1px solid #e6e6e6;
  align-items: left;
  text-align: left;
}

.Feedback-card-content {
  height: fit-content;
}

button[class*="Feedback-send-button"] {
  width: 90vw;
  height: 5vh;
  background-color: gray;
  color: #ffffff;
  /* border: #003d7b; */
  text-transform: capitalize;
  font-size: 3.5vw;
  border-radius: 5vw;
  margin-top: 3.5vw;
}

button[class*="Feedback-send-button"].enable {
  /* background-color: #003d7b; */
}

button[class*="Feedback-close-button"] {
  width: 90vw;
  height: 5vh;
  background-color: #ffffff;
  /* color: #003d7b; */
  border: 1px solid #003d7b;
  font-size: 3.5vw;
  text-transform: capitalize;
  border-radius: 5vw;
  margin-top: 1vw;
}

.Feedback-grid {
  display: grid;
}
