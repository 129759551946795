.Error-page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Error-page-content {
  text-align: center;
  position: fixed;
  height: 70%;
  width: 100%;
  bottom: 0;
}

.Error-page-footer {
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 5%;
}

.Error-page-logo {
  width: 116.7px;
  height: 52.5px;
  margin-top: 16px;
  margin-bottom: 14px;
}

.content-inner {
  max-width: 1080px;
  margin-right: auto;
  margin-left: auto;
  font-size:10px;
  color: white;
}

.error-inner{
  background-color: #00479d;
  min-width: 1120px;
  height: 30px;
  line-height: 30px;
}
.header-content-pc{
  height: 90px;
  min-width: 1120px;
  background-color: var(--ion-color-custom-white);
}
.header-content{
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: var(--min-height);
  contain: content;
  overflow: hidden;
  z-index: 10;
  box-sizing: border-box;
}

.header-content-button{
  width: 183.5px;
}

.header-content-logo{
  min-width: 0px;
  max-width: 100%;
}

.header-content-all{
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
}

.text-top{
  margin: 0;
  color: #333333;
  line-height: 13px;
  text-align: right;
  font-weight: 700;
  font-size: 10px;
  font-family: hiraginoKakuGothicPro-W6,Meiryo,メイリオ,-apple-system,BlinkMacSystemFont,Roboto-W6,NotoSans-W6,system-ui,Verdana,sans-serif;
}

.text-bottom{
  margin: 0;
  line-height: 30px;
  letter-spacing: normal;
  font-weight: 700;
  font-size: 24px;
  font-family: Segoe UI,Helvetica Neue,Helvetica,ヒラギノ角ゴ ProN,HiraKakuProN,ヒラギノ角ゴ Pro,HiraKakuPro,Hiragino Kaku Gothic ProN,Hiragino Kaku Gothic Pro,Hiragino Sans,Meiryo,メイリオ,-apple-system,BlinkMacSystemFont,Roboto,Noto Sans,Noto Sans CJK JP,Noto Sans JP,system-ui,Verdana,sans-serif !important;
}

.phone-icon {
  width: 22px !important;
  height: 22px !important;
  margin-top: 4px;
  margin-right: 4px;
}

.text-top-phone{
  display: flex;
}

.header-border-normal{
  background: #dcdcdc;
    border: 0.5px solid #dcdcdc;
}
.content-box{
  min-width: 1120px;
  min-height: 475px;
  padding-top: 50px;
  padding-bottom: 100px;
}

.content-box main{
  margin-right: auto;
  margin-left: auto;
  max-width: 1080px;
}
.content-box h1{
  margin: 0;
}

.content-box p{
  font-size: 14px;
  font-family: hiraginoKakuGothicPro-W3,Meiryo,メイリオ,-apple-system,BlinkMacSystemFont,Roboto-W3,NotoSans-W3,system-ui,Verdana,sans-serif;
  font-weight: 400;
  line-height: 20px;
  color: #333333;
  margin-top: 20px;
}

.footer-border-normal{
  min-width: 1120px;
  border-top: 2px solid #FFE100;
  background-color: #f6f5f3;
  height: 106px;
}

.footer-text{
  display: flex;
  margin-right: auto;
  margin-left: auto;
  max-width: 1080px;
  padding-top: 25px;
}
.footer-text p{
  margin: 0;
  margin-right: 8px;
  font-size: 12px;
  color: #5b5b5b;
  font-weight: 400;
}

.footer-text-bottom{
  display: flex;
  margin-right: auto;
  margin-left: auto;
  max-width: 1080px;
  padding-top: 10px;
}

.footer-text-bottom p{
  margin: 0;
  margin-right: 4px;
  font-size: 12px;
  color: #5b5b5b;
  font-weight: 400;
}

