fieldset.MuiOutlinedInput-notchedOutline {
  border: none;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: lawngreen !important;
}

.RadioGroup svg.MuiSvgIcon-root {
  width: 0.8em;
  height: 0.8em;
  color: rgb(0, 61, 123);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(../../assets/icons/calendar.svg) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}

/* Avatar custom CSS */
.schedule-avatar {
  float: left;
  margin: 5px;
  border-radius: 0 !important;
  width: 36.5vw !important;
  height: auto !important;
  border-color: transparent;
  border-style: solid;
  border-width: 2px;
}
.schedule-avatar.selected {
  border-color: #ea5205;
  border-style: solid;
}

button[class*="feedback_sendbutton"] {
  width: 90vw;
  height: 5vh;
  background-color: gray;
  color: #ffffff;
  /* border: #003d7b; */
  text-transform: capitalize;
  font-size: 3.5vw;
  border-radius: 5vw;
  margin-top: 3.5vw;
  font-family: ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, 新ゴR, メイリオ,
    Meiryo, Verdana, ＭＳ Ｐゴシック, sans-serif;
}

button[class*="feedback_sendbutton"].enable {
  /* background-color: #003d7b; */
}

button[class*="feedback_closebutton"] {
  width: 90vw;
  height: 5vh;
  background-color: #ffffff;
  /* color: #003d7b; */
  /* border: 1px solid #003d7b; */
  font-size: 3.5vw;
  text-transform: capitalize;
  border-radius: 5vw;
  margin-top: 1vw;
  font-family: ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, 新ゴR, メイリオ,
    Meiryo, Verdana, ＭＳ Ｐゴシック, sans-serif;
}
