
.AppBar-title {
  font-family: "ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, 新ゴR, メイリオ, Meiryo, Verdana, ＭＳ Ｐゴシック, sans-serif" !important;
  flex-grow: 1;
  text-align: center !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  color: #ffffff !important;
  line-height: 21px !important;
  position: sticky;
  padding-left: 60px !important;
  letter-spacing: 0.06em !important;
}

.AppBar-langButton {
  border-radius: 10px 10px 10px 10px;
  color: inherit;
  font-size: 100%;
  text-transform: none;
}

.AppBar-toolbar {
  background: white;
  height: 55px;
  border-bottom:1px solid #dcdcdc;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
}

.AppBar-helpIcon {
  background: none;
  border-bottom: none;
  border-top: none;
  border-right: none;
  border-left: 1px solid #dcdcdc;
  height: 55px;
  min-width: 65px;
  align-items: center;
}

.promise-logo{
  width: 76.8px;
  height: 34.3px;
  margin-left: 16px;
  vertical-align: bottom;
}

.promise-help{
  width: 26px;
}

.AppBar-toolbar-div{
  width: 100%;
}
.help-font{
  font-size: 10px;
  font-family: hiraginoKakuGothicPro-W6,Meiryo,メイリオ,-apple-system,BlinkMacSystemFont,Roboto-W6,NotoSans-W6,system-ui,Verdana,sans-serif;
  padding-top: 1px;
  line-height: 10px;
  font-weight: 700;
  color: #333333;
}